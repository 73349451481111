body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@font-face {
  font-family: 'Poppins400';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts/poppins_regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins500';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts/poppins_medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins600';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts/poppins_semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins700';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts/poppins_bold.ttf') format('truetype');
}
